window.gridImageTextHandler = () => {
  const gridModule = document.querySelectorAll('.jsGridImageText');

  if (gridModule) {
    gridModule.forEach(grid => {
      const gridContainer = grid.querySelector('.jsGridImageTextGrid'),
        gridImage = grid.querySelector('.jsGridImageTextImg'),
        imageHeight = gridImage.offsetHeight;

      window.innerWidth > 767 ? gridContainer.style.height = `${imageHeight}px` : gridContainer.style.height = ''
    });
  }
};