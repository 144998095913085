import Swiper from "swiper";

window.topSideSwiper = () => {
  const topSide = new Swiper('.jsTopSideSwiper', {
    autoplay: {
      delay: 5000,
    },
    loop: true,
    pagination: {
      el: '.jsTopSideSwiperPagination',
      clickable: true
    },
  });
};